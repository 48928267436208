import { tv } from "tailwind-variants";

export const copy = tv(
  {
    base: "m-0",
    variants: {
      variant: {
        "24": "text-2xl",
        "20": "text-xl",
        "18": "text-lg",
        "16": "text-base",
        "14": "text-sm",
        "12": "text-xs",
      },
      color: {
        primary: "text-ds-text-primary",
        secondary: "text-ds-text-secondary",
        tertiary: "text-ds-text-tertiary",
        disabled: "text-ds-text-disabled",
      },
      weight: {
        normal: "font-normal",
        medium: "font-medium",
        semibold: "font-semibold",
      },
      align: {
        left: "text-left",
        center: "text-center",
        right: "text-right",
      },
      prose: {
        true: "max-w-prose",
        false: "max-w-none",
      },
    },
    defaultVariants: {
      variant: "16",
      color: "primary",
      weight: "normal",
      align: "left",
      prose: true,
    },
  },
  {
    responsiveVariants: true,
  },
);
