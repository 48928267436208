import { InfiniteData, infiniteQueryOptions, queryOptions } from "@tanstack/react-query";
import api from "shared/api/api";
import { Params } from "shared/api/types/params";
import { UserProfileResponse } from "shared/api/user/user";
import { RoleType } from "shared/constants/savedRoles.constants";
import { PaginatedResponse } from "shared/types/pagination.types";
import { addFullNameToDataItems } from "shared/util/addFullNameToDataItems";
import { createInfiniteDataTransform } from "shared/util/createInfiniteDataTransform";

export const PARTICIPANT_KEYS = {
  all: ["participant"] as const,
  list: (params: Params) => [...PARTICIPANT_KEYS.all, "list", params] as const,
  detail: (id?: string) => [...PARTICIPANT_KEYS.all, id] as const,
  highTouch: (params: Params) => [...PARTICIPANT_KEYS.all, "high-touch", params] as const,
  userStatuses: (params: Params) => [...PARTICIPANT_KEYS.all, "user-statuses", params] as const,
  savedRoles: (participantId: string) => [...PARTICIPANT_KEYS.detail(participantId), "savedRoles"] as const,
  savedRolesList: (participantId: string, params: Params) =>
    [...PARTICIPANT_KEYS.savedRoles(participantId), params] as const,
  savedRolesDetail: (participantId: string, savedRoleId: number) =>
    [...PARTICIPANT_KEYS.savedRoles(participantId), "detail", savedRoleId] as const,
};

export const PARTICIPANT_QUERIES = {
  participant: (id?: string) => {
    return queryOptions({
      queryKey: PARTICIPANT_KEYS.detail(id),
      queryFn: async ({ signal }) => {
        if (!id) return null;

        const response = await api.get<UserProfileResponse>(`/participants/${id}`, { signal });

        return response.data;
      },
    });
  },
  search: (params: Params) => {
    return queryOptions({
      queryKey: [...PARTICIPANT_KEYS.all, "search", params] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get<ParticipantSearchResponse>("/search/participants", {
          ...params,
          signal,
        });

        return response.data;
      },
      staleTime: 1000 * 60 * 1, // 1 minute
    });
  },
  infiniteUserStatusesList: (params: { [key: string]: unknown }) => {
    const { limit = 20 } = params ?? {};

    return infiniteQueryOptions({
      queryFn: async ({ pageParam = 1, signal }) => {
        const response = await api.get(`/user-statuses`, {
          params: {
            ...params,
            limit,
            skip: (pageParam - 1) * (limit as number),
          },
          signal,
        });

        return response.data;
      },
      queryKey: PARTICIPANT_KEYS.userStatuses({
        params: {
          ...params,
          limit,
        },
      }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
      select: (
        data: InfiniteData<
          {
            items: {
              id: number;
              type: string;
            }[];
          },
          number
        >,
      ) => {
        return createInfiniteDataTransform(data, (item) => {
          return {
            ...item,
            name: item.type,
          };
        });
      },
    });
  },
  infiniteParticipantsList: (params: { [key: string]: unknown }) => {
    const { limit = 20, all = "true", orderDir = "ASC", orderBy = "name" } = params ?? {};
    return infiniteQueryOptions({
      queryFn: async ({ pageParam = 1, signal }) => {
        const response = await api.get(`/participants`, {
          params: {
            ...params,
            limit,
            all,
            orderBy,
            orderDir,
            skip: (pageParam - 1) * (limit as number),
          },
          signal,
        });

        return response.data;
      },
      queryKey: PARTICIPANT_KEYS.list({
        params: {
          ...params,
          limit,
          all,
          orderBy,
          orderDir,
        },
      }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
      select: addFullNameToDataItems,
    });
  },
  savedRolesList: (participantId: string, params: Params) => {
    return queryOptions({
      queryKey: PARTICIPANT_KEYS.savedRolesList(participantId, params),
      queryFn: async ({ signal }) => {
        const response = await api.get<SavedRolesResponse>(`/participants/${participantId}/saved/roles`, {
          ...params,
          signal,
        });

        return response.data;
      },
    });
  },
  savedRoleDetail: <TRoleType extends RoleType>(participantId: string, savedRoleId: number) => {
    return queryOptions({
      queryKey: PARTICIPANT_KEYS.savedRolesDetail(participantId, savedRoleId),
      queryFn: async ({ signal }) => {
        const response = await api.get<SavedRoleDetailResponse<TRoleType>>(
          `/participants/${participantId}/saved/roles/${savedRoleId}`,
          {
            signal,
          },
        );

        return response.data;
      },
    });
  },
};

export interface ParticipantSearchItem {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  startDate?: number;
  statuses?: Array<string | { type: string; context: string }>;
  compensation?: string;
  _snippetResult?: Record<
    MatchTypes,
    {
      value: string;
      matchLevel: "none" | "full" | "partial";
    }
  >;
  linkedin?: string;
  finalResume?: string;
  headshot?: {
    location: string;
  };
  participantManager?: {
    id: number;
    firstName: string;
    lastName: string;
    headshot?: {
      location: string;
    };
  };
  oneLiner?: string;
  locations?: Array<string>;
  skills?: Array<string>;
  currentPosition?: JobPosition;
  gamePlanId?: number | null;
  workHistory?: Array<JobPosition>;
  education?: Array<UserEducation>;
}

export interface UserEducation {
  id: number;
  school: string | null;
  degree: string | null;
  fieldOfStudy: string | null;
  start: number | null;
  end: number | null;
}

export interface JobPosition {
  id: number;
  role: {
    title: string;
  };
  company: {
    id: number | null;
    name: string | null;
  };
  startDate: string | null;
  endDate: string | null;
}
export interface ParticipantSearchResponse {
  total: {
    hits: number;
    pages: number;
  };
  items: Array<ParticipantSearchItem>;
  page: number;
  limit: number;
}
export type HighlightMatch = NonNullable<ParticipantSearchItem["_snippetResult"]>[MatchTypes];
export type SnippetResult = NonNullable<ParticipantSearchItem["_snippetResult"]>;

export const PARTICIPANT_SEARCH_SCOPES_OPTIONS = {
  Name: "participant.name",
  "One Liner": "participant.oneLiner",
  Email: "participant.email",
} as const;
export const OTHER_SCOPES_OPTIONS = {
  Resume: "resume.content",
  Application: "application.content",
  Reference: "reference.content",
  "Action Item": "actionItem.content",
} as const;

export const ALL_SCOPE_OPTIONS = {
  ...PARTICIPANT_SEARCH_SCOPES_OPTIONS,
  ...OTHER_SCOPES_OPTIONS,
} as const;

export type MatchTypes = (typeof ALL_SCOPE_OPTIONS)[keyof typeof ALL_SCOPE_OPTIONS];

interface BaseSavedRole {
  id: number;
  isRemote: boolean;
  compensation?: number;
  payType?: PayType;
  notes?: string;
  rank?: RankType;
}

export interface InternalSavedRole extends BaseSavedRole {
  type: "internal";
  job: {
    id: number;
    title: string;
    url?: string;
  };
  company: {
    id: number;
    name: string;
    logo?: {
      location: string;
    };
  };
}

export interface ExternalSavedRole extends BaseSavedRole {
  type: "external";
  job: string;
  url?: string;
  company:
    | string
    | {
        id: number;
        name: string;
        logo?: {
          location: string;
        };
      };
}

export type SavedRolesResponse = PaginatedResponse<InternalSavedRole | ExternalSavedRole>;

export type SavedRoleDetailResponse<RoleType> = RoleType extends "internal" ? InternalSavedRole : ExternalSavedRole;

export type PayType = "Salary" | "Hourly";
export const RANKS = ["Low", "Medium", "High"] as const;
export type RankType = (typeof RANKS)[number];

// CREATE
export interface SaveRoleBody {
  job: string | number;
  company: string | number;
  isRemote?: boolean;
  compensation?: number;
  payType?: PayType;
  notes?: string;
  rank?: RankType | null;
  url?: string;
}

// UPDATE
export type UpdateRoleBody = Partial<SaveRoleBody>;

export interface SaveInternalRoleBody {
  jobId: number;
  compensation?: number;
  payType?: PayType;
  notes?: string;
  rank?: RankType | null;
}

export interface SaveExternalRoleBody {
  company: string;
  title: string;
  compensation?: number;
  payType?: PayType;
  notes?: string;
  rank?: RankType | null;
  url?: string;
}
